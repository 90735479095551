import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./containers/About";
import Home from "./containers/Home";
import Products from "./containers/Products";
import ScrollToTop from "./containers/ScrollToTop";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/products" element={<Products />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
