import React from "react";
import "../assets/css/style.css";
import Feature from "../components/Feature";
import Hero from "../components/Hero";
import Marketplace from "../components/Marketplace";
import Feature2 from "../components/Feature2";
import Free from "../components/Free";
import Testimonial from "../components/Testimonial";
import Started from "../components/Started";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Popular from "../components/Popular";

function Home() {
    return (
        <main>
            <Navbar />
            <Hero />
            <Popular />
            <Marketplace />
            <Feature />
            <Feature2 />
            <Free />
            <Testimonial />
            <Started />
            <Footer />
            <a
                href="https://play.google.com/store/apps/details?id=brecha.treemarket.intapp&gl=US"
                target="_blank"
                rel="noreferrer noopener"
                className="btn fixed-btn"
            >
                Download TreeMarket
            </a>
        </main>
    );
}
export default Home;
