import React from "react";
import { Link } from "react-router-dom";
import popularItems from "../data/popularItems";

function Popular() {
    // Generate Popular Products List From PopularItems Data
    const generateItems = popularItems.map((item, index) => (
        <div className="popular-product" key={item.name + index}>
            <div className="popular-product-img">
                <img
                    src={require(`../assets/images/products/${item.img}`)}
                    alt={item.name}
                />
            </div>
            <p className="product-price">ZK{item.price}.00</p>
            <p className="product-location">
                {item.city}, {item.country}
            </p>
        </div>
    ));
    return (
        // <section className="popular-section">
        //     <div className="container">
        //         <div className="popular-content">
        //             <h4>What's popular right now in TreeMarket App?</h4>
        //             <div className="popular-products">{generateItems}</div>

        //             <Link to="/products">DISCOVER MORE</Link>
        //         </div>
        //     </div>
        // </section>
            <section className="popular-section">
                <div className="container">
                     <div className="popular-content">
                        <h4>What's popular right now in TreeMarket App?</h4>
                        <div className="popular-products">{generateItems}</div>

        <Link to="/products" style={{ textDecoration: 'none', color: 'green' }}>
            DISCOVER MORE
        </Link>
        </div>
    </div>
    </section>

    );
}
export default Popular;
