import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import featureImg from "../assets/images/feature_2.jpg";
import message_1 from "../assets/images/messages/message_1.jpg";
import message_2 from "../assets/images/messages/message_2.jpg";
import message_3 from "../assets/images/messages/message_3.jpg";
import message_4 from "../assets/images/messages/message_4.jpg";
import message_5 from "../assets/images/messages/message_5.jpg";
import message_6 from "../assets/images/messages/message_6.jpg";
import message_7 from "../assets/images/messages/message_7.jpg";

function Feature2() {
    // On Scroll Animation Initialize
    useEffect(() => {
        AOS.init({
            duration: 500,
            delay: 100,
            once: true,
        });
    }, []);

    return (
        // Feature Section
        <section className="feature-section feature-2">
            <div className="container">
                <div className="feature-content">
                    <div className="mockup-img" data-aos="fade-up">
                        <img src={featureImg} alt="Feature" />
                        <div className="messages">
                            <img
                                src={message_1}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="1000"
                            />
                            <img
                                src={message_2}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="1200"
                            />
                            <img
                                src={message_3}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="1600"
                            />
                            <img
                                src={message_4}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="2000"
                            />
                            <img
                                src={message_5}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="2300"
                            />
                            <img
                                src={message_6}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="2600"
                            />
                            <img
                                src={message_7}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="3000"
                            />
                        </div>
                    </div>
                    <div className="feature-text">
                        <div>
                            <h3 className="secondary-heading">
                                <span>Easy peasy </span>
                                <br />
                                List stuff you don't need,
                                <br />
                                search for a great bargain or
                                <br />
                                chat to buy
                            </h3>
                        </div>
                        <div className="feature-icon">
                            <div>
                                <svg
                                    width="18"
                                    height="18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M12.354 9.354a.5.5 0 0 0-.708-.708L8 12.293l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4Z"
                                        fill="#696F70"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6 1.5a.5.5 0 0 1 .5.5v.5h5V2a.5.5 0 0 1 1 0v.5H15A1.5 1.5 0 0 1 16.5 4v10a2.5 2.5 0 0 1-2.5 2.5H4A2.5 2.5 0 0 1 1.5 14V4A1.5 1.5 0 0 1 3 2.5h2.5V2a.5.5 0 0 1 .5-.5ZM5.5 4v-.5H3a.5.5 0 0 0-.5.5v1.5h13V4a.5.5 0 0 0-.5-.5h-2.5V4a.5.5 0 0 1-1 0v-.5h-5V4a.5.5 0 0 1-1 0Zm-3 10V6.5h13V14a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 14Z"
                                        fill="#696F70"
                                    ></path>
                                    <path
                                        d="M12.354 9.354a.5.5 0 0 0-.708-.708L8 12.293l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4Z"
                                        stroke="#696F70"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        clipRule="evenodd"
                                        d="M6 1.5a.5.5 0 0 1 .5.5v.5h5V2a.5.5 0 0 1 1 0v.5H15A1.5 1.5 0 0 1 16.5 4v10a2.5 2.5 0 0 1-2.5 2.5H4A2.5 2.5 0 0 1 1.5 14V4A1.5 1.5 0 0 1 3 2.5h2.5V2a.5.5 0 0 1 .5-.5ZM5.5 4v-.5H3a.5.5 0 0 0-.5.5v1.5h13V4a.5.5 0 0 0-.5-.5h-2.5V4a.5.5 0 0 1-1 0v-.5h-5V4a.5.5 0 0 1-1 0Zm-3 10V6.5h13V14a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 14Z"
                                        stroke="#696F70"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>

                                <h6>Meet Up</h6>
                            </div>
                            <div>
                                <svg
                                    width="18"
                                    height="18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.853 5.256a2.197 2.197 0 1 0-3.107 3.107 2.197 2.197 0 0 0 3.107-3.107Zm-2.4.707a1.197 1.197 0 1 1 1.693 1.692 1.197 1.197 0 0 1-1.693-1.692Z"
                                        fill="#696F70"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.254 1.747a2.5 2.5 0 0 0-2.374-.657l-3.164.79a2.5 2.5 0 0 0-1.162.658L1.56 8.532a2.5 2.5 0 0 0 0 3.536l4.372 4.371a2.5 2.5 0 0 0 3.535 0l5.994-5.994a2.5 2.5 0 0 0 .658-1.161l.791-3.164a2.5 2.5 0 0 0-.657-2.374l-1.999-1.999Zm-2.132.313a1.5 1.5 0 0 1 1.425.394l1.998 1.999a1.5 1.5 0 0 1 .395 1.424l-.791 3.164a1.5 1.5 0 0 1-.395.697L8.76 15.732a1.5 1.5 0 0 1-2.121 0l-4.372-4.371a1.5 1.5 0 0 1 0-2.122l5.995-5.994a1.5 1.5 0 0 1 .696-.394l3.164-.791Z"
                                        fill="#696F70"
                                    ></path>
                                    <path
                                        clipRule="evenodd"
                                        d="M12.853 5.256a2.197 2.197 0 1 0-3.107 3.107 2.197 2.197 0 0 0 3.107-3.107Zm-2.4.707a1.197 1.197 0 1 1 1.693 1.692 1.197 1.197 0 0 1-1.693-1.692Z"
                                        stroke="#696F70"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        clipRule="evenodd"
                                        d="M14.254 1.747a2.5 2.5 0 0 0-2.374-.657l-3.164.79a2.5 2.5 0 0 0-1.162.658L1.56 8.532a2.5 2.5 0 0 0 0 3.536l4.372 4.371a2.5 2.5 0 0 0 3.535 0l5.994-5.994a2.5 2.5 0 0 0 .658-1.161l.791-3.164a2.5 2.5 0 0 0-.657-2.374l-1.999-1.999Zm-2.132.313a1.5 1.5 0 0 1 1.425.394l1.998 1.999a1.5 1.5 0 0 1 .395 1.424l-.791 3.164a1.5 1.5 0 0 1-.395.697L8.76 15.732a1.5 1.5 0 0 1-2.121 0l-4.372-4.371a1.5 1.5 0 0 1 0-2.122l5.995-5.994a1.5 1.5 0 0 1 .696-.394l3.164-.791Z"
                                        stroke="#696F70"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>

                                <h6>Make Offer</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // Feature Section End
    );
}
export default Feature2;
