import React from "react";
import { useState } from "react";
import locations from "../data/locations";
import itemsData from "../data/itemsData";
import { useEffect } from "react";

function Items(props) {
    // Filters State
    const [cityFilter, setCityFilter] = useState("Lusaka");
    const [countryFilter, setCountryFilter] = useState("Zambia");

    // Set City Products Page
    useEffect(() => props.setCity(cityFilter), [cityFilter, props]);

    // Change City Filter State On City Select Change
    const changeCityFilter = (e) => {
        setCityFilter(e.target.value);
    };

    // Change Country Filter State On Country Select Change
    const changeCountryFilter = (e) => {
        setCountryFilter(e.target.value);
    };

    // Generate All Countries List From Location Data
    const generatedCountries = locations.map((location, index) => (
        <option value={location.country} key={location.country + index}>
            {location.country}
        </option>
    ));

    // Generate All Cities List From Location Data Within Selected Country
    const generatedCities = locations
        .filter(
            (location, index) =>
                location.country.toLowerCase() === countryFilter.toLowerCase()
        )[0]
        .cities.map((city, index) => (
            <option value={city} key={city + index}>
                {city}
            </option>
        ));

    // Generate Products List With Matching Location
    const generatedItems = itemsData
        .filter(
            (item, index) =>
                item.city.toLowerCase() === cityFilter.toLowerCase() &&
                item.country.toLowerCase() === countryFilter.toLowerCase()
        )
        .map((item, index) => (
            <div className="item" key={item.name + index}>
                <div className="item-img">
                    <img
                        src={require(`../assets/images/products/${item.img}`)}
                        alt={item.name}
                    />
                </div>
                <p className="item-name">{item.sale}</p>
                <p className="item-price">ZK{item.price}.00</p>
                <p className="item-location">
                    {item.city}, {item.country}
                </p>
                <p className="item-info">
                    {item.favorites} favorites ∙ {item.chats} chats
                </p>
            </div>
        ));
    return (
        <section className="items-section">
            <div className="container">
                <div className="items-content">
                    <h3>Popular items in {cityFilter}</h3>
                    <div className="item-filters">
                        <select
                            name="cities"
                            id="cites"
                            onChange={changeCityFilter}
                        >
                            {generatedCities}
                        </select>
                        <select
                            name="country"
                            id="country"
                            onChange={changeCountryFilter}
                        >
                            {generatedCountries}
                        </select>
                    </div>
                    <div className="items">
                        {generatedItems.length > 0 ? (
                            generatedItems
                        ) : (
                            <div>
                                <br />
                                <h3>No items Found</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Items;
