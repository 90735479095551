import React from "react";
import Navbar from "../components/Navbar";
import Items from "../components/Items";
import Footer from "../components/Footer";
import More from "../components/More";
import { useState } from "react";

function Products() {
    const [city, setCity] = useState();

    return (
        <main className="products-page">
            <Navbar />
            <Items setCity={setCity} />
            <More city={city} />
            <Footer />
            <a
                href="https://play.google.com/store/apps/details?id=brecha.treemarket.intapp&gl=US"
                target="_blank"
                rel="noreferrer noopener"
                className="btn fixed-btn"
            >
                Join TreeMarket
            </a>
        </main>
    );
}
export default Products;
