import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import featureImg from "../assets/images/feature.jpg";
import review from "../assets/images/review.jpg";

function Feature() {
    // On Scroll Animation Initialize
    useEffect(() => {
        AOS.init({
            duration: 500,
            delay: 100,
            once: true,
        });
    }, []);

    return (
        // Feature Section
        <section className="feature-section">
            <div className="container">
                <div className="feature-content">
                    <div className="feature-text">
                        <div>
                            <h3>
                                <span>Real people like you </span>
                                <br />
                                All of our members are
                                <br />
                                verified and each member's <br />
                                review is clearly shown
                            </h3>
                        </div>
                        <div className="feature-icon">
                            <div>
                                <svg
                                    width="18"
                                    height="18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9 1.5c-3.307 0-6 2.72-6 6.088 0 2.264 1.356 4.388 2.867 6.04A20.554 20.554 0 0 0 9 16.393a20.556 20.556 0 0 0 3.133-2.765C13.644 11.976 15 9.852 15 7.588 15 4.22 12.307 1.5 9 1.5ZM2 7.588C2 3.68 5.128.5 9 .5s7 3.18 7 7.088c0 2.645-1.567 5.007-3.13 6.715a21.26 21.26 0 0 1-3.54 3.078.59.59 0 0 1-.66 0 21.258 21.258 0 0 1-3.54-3.078C3.566 12.595 2 10.233 2 7.588ZM9 5.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
                                        fill="#706E6E"
                                        stroke="#706E6E"
                                        strokeWidth="0.5"
                                        strokeLinecap="round"
                                    ></path>
                                </svg>

                                <h6>Verified users</h6>
                            </div>
                            <div>
                                <svg
                                    width="18"
                                    height="18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.646 1.896a.5.5 0 0 1 .708 0l3.75 3.75a.5.5 0 0 1 0 .708l-9.75 9.75A.5.5 0 0 1 6 16.25H2.25a.5.5 0 0 1-.5-.5V12a.5.5 0 0 1 .146-.354l9.75-9.75Zm1.104 6.397-1.896-1.897a.5.5 0 0 0-.708.708L12.043 9l-6.25 6.25H2.75v-3.043L12 2.957 15.043 6 12.75 8.293Z"
                                        fill="#706E6E"
                                    ></path>
                                    <path
                                        d="M9.75 15.75a.5.5 0 0 1 .5-.5h5.65a.5.5 0 0 1 0 1h-5.65a.5.5 0 0 1-.5-.5Z"
                                        fill="#706E6E"
                                    ></path>
                                    <path
                                        clipRule="evenodd"
                                        d="M11.646 1.896a.5.5 0 0 1 .708 0l3.75 3.75a.5.5 0 0 1 0 .708l-9.75 9.75A.5.5 0 0 1 6 16.25H2.25a.5.5 0 0 1-.5-.5V12a.5.5 0 0 1 .146-.354l9.75-9.75Zm1.104 6.397-1.896-1.897a.5.5 0 0 0-.708.708L12.043 9l-6.25 6.25H2.75v-3.043L12 2.957 15.043 6 12.75 8.293Z"
                                        stroke="#706E6E"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        d="M9.75 15.75a.5.5 0 0 1 .5-.5h5.65a.5.5 0 0 1 0 1h-5.65a.5.5 0 0 1-.5-.5Z"
                                        stroke="#706E6E"
                                        strokeWidth="0.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>

                                <h6>Feedback review</h6>
                            </div>
                        </div>
                    </div>
                    <div className="mockup-img" data-aos="fade-up">
                        <img src={featureImg} alt="Feature" />
                        <div className="mockup-review">
                            <img
                                src={review}
                                alt=""
                                data-aos="zoom-in"
                                data-aos-delay="600"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // Feature Section End
    );
}
export default Feature;
