import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
        // Footer
        <footer>
            <div className="container">
                <div className="footer-content">
                    <ul className="footer-list">
                        <li>
                            <Link to="/about">About us</Link>
                        </li>
                        <li>
                            <Link to="/">Terms</Link>
                        </li>
                        <li>
                            <Link to="/">Privacy</Link>
                        </li>
                        <li>
                            <Link to="/">FAQ</Link>
                        </li>
                        <li>
                            <a href="treemarketapp@gmail.com ">
                                treemarketapp@gmail.com
                            </a>
                        </li>
                    </ul>
                    <ul className="footer-list">
                        <li>
                            <a
                                href="https://www.facebook.com/TreeMarket"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="footer-social"
                            >
                                <svg
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M27.5 13.975C27.5 6.69 21.403.79 13.877.79 6.351.791.254 6.691.254 13.975c0 6.58 4.983 12.035 11.492 13.025v-9.217H8.282v-3.808h3.464v-2.907c0-3.304 2.034-5.127 5.146-5.127 1.49 0 3.052.258 3.052.258v3.24h-1.72c-1.689 0-2.222 1.014-2.222 2.057v2.473h3.778l-.605 3.808h-3.173v9.211c6.515-.984 11.498-6.433 11.498-13.013Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/treemarket_make_trade_easy/?hl=en"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="footer-social"
                            >
                                <svg
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M13.5 3.447c3.605 0 4.032.013 5.455.076 1.316.057 2.031.27 2.507.447.63.236 1.08.518 1.552.973.473.455.765.888 1.01 1.494.185.459.405 1.147.465 2.414.065 1.371.078 1.782.078 5.253 0 3.472-.013 3.883-.078 5.254-.06 1.267-.28 1.955-.465 2.414a4.018 4.018 0 0 1-1.01 1.494 4.2 4.2 0 0 1-1.552.973c-.476.178-1.19.39-2.507.447-1.423.063-1.85.076-5.455.076s-4.032-.013-5.455-.076c-1.316-.057-2.031-.27-2.507-.447a4.2 4.2 0 0 1-1.552-.973 4.017 4.017 0 0 1-1.01-1.494c-.185-.459-.405-1.147-.465-2.414-.065-1.371-.079-1.782-.079-5.254 0-3.47.014-3.882.08-5.253.06-1.267.28-1.955.464-2.414a4.016 4.016 0 0 1 1.01-1.494 4.202 4.202 0 0 1 1.552-.973c.476-.178 1.19-.39 2.507-.447 1.423-.063 1.85-.076 5.455-.076Zm0-2.343c-3.666 0-4.126.015-5.566.079-1.437.063-2.418.283-3.277.604a6.649 6.649 0 0 0-2.391 1.5A6.353 6.353 0 0 0 .709 5.588C.375 6.416.147 7.361.08 8.745.015 10.13 0 10.574 0 14.105c0 3.53.015 3.973.081 5.36.066 1.383.294 2.328.628 3.155.345.855.806 1.58 1.557 2.303a6.648 6.648 0 0 0 2.39 1.499c.86.321 1.841.541 3.278.604 1.44.064 1.9.079 5.566.079s4.126-.015 5.566-.079c1.437-.063 2.418-.283 3.277-.604a6.648 6.648 0 0 0 2.391-1.5 6.353 6.353 0 0 0 1.557-2.302c.334-.827.562-1.772.628-3.156.066-1.386.081-1.829.081-5.36 0-3.53-.015-3.973-.081-5.36-.066-1.383-.294-2.328-.628-3.155a6.353 6.353 0 0 0-1.557-2.303 6.648 6.648 0 0 0-2.391-1.499c-.859-.321-1.84-.541-3.277-.604-1.44-.064-1.9-.079-5.566-.079Zm0 6.325c-3.829 0-6.932 2.989-6.932 6.675 0 3.687 3.103 6.676 6.932 6.676s6.932-2.989 6.932-6.676c0-3.686-3.103-6.675-6.932-6.675Zm0 11.009c-2.485 0-4.5-1.94-4.5-4.333 0-2.394 2.015-4.334 4.5-4.334s4.5 1.94 4.5 4.333c0 2.394-2.015 4.334-4.5 4.334Zm8.826-11.273c0 .862-.725 1.56-1.62 1.56-.894 0-1.62-.698-1.62-1.56 0-.862.726-1.56 1.62-1.56.895 0 1.62.698 1.62 1.56Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/treemarket-app-709ab6240/"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="footer-social"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 50 50"
                                    width="500"
                                    height="500"
                                >
                                    <path
                                        d="M8 3.0097656C4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188C2.0097656 10.819219 4.59 12.990234 8 12.990234C11.47 12.990234 13.990234 10.870625 13.990234 7.890625C13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15C2.45 15 2 15.45 2 16L2 45C2 45.55 2.45 46 3 46L13 46C13.55 46 14 45.55 14 45L14 16C14 15.45 13.55 15 13 15L3 15 z M 18 15C17.45 15 17 15.45 17 16L17 45C17 45.55 17.45 46 18 46L27 46C27.552 46 28 45.552 28 45L28 30L28 29.75L28 29.5C28 27.13 29.820625 25.199531 32.140625 25.019531C32.260625 24.999531 32.38 25 32.5 25C32.62 25 32.739375 24.999531 32.859375 25.019531C35.179375 25.199531 37 27.13 37 29.5L37 45C37 45.552 37.448 46 38 46L47 46C47.55 46 48 45.55 48 45L48 28C48 21.53 44.529063 15 36.789062 15C33.269062 15 30.61 16.360234 29 17.490234L29 16C29 15.45 28.55 15 28 15L18 15 z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <p className="copyright">©TreeMarket Inc.</p>
            </div>
        </footer>
        // Footer End
    );
}
export default Footer;
