import React from "react";
function Testimonial() {
    return (
        <section className="testimonial-section">
            <div className="container">
                <div className="testimonial-content">
                    <h5>
                        Over 10 thousand people around the Zambia are using
                        TreeMarket
                    </h5>

                    <div className="testimonial-flex">
                        <div className="testimonial-box">
                            <div>
                                <svg
                                    width="40"
                                    height="32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M18.257 23.12c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24C10.227.9.107 5.85.107 20.59c0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Zm21.67 0c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24c-6.93.66-17.05 5.61-17.05 20.35 0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Z"
                                        fill="#EAEBEE"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <p>
                                    TreeMarket is a
                                    <span> breath of fresh air.</span> I was
                                    looking for a fridge. Found one I liked,
                                    messaged the seller with TreeMarket's
                                    messaging feature, and the fridge was in my home
                                    within 12 hours.
                                    <span>
                                        {" "}
                                        Great selling and buying experience!
                                    </span>
                                </p>
                                <div className="testimonial-author">
                                    <div>
                                        <p className="para-2">Chewe</p>
                                    </div>
                                    <div>
                                        <svg
                                            width="14"
                                            height="14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            role="img"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.008.5C4.128.5 1.8 2.934 1.8 5.926c0 2.025 1.166 3.832 2.328 5.14a15.937 15.937 0 0 0 2.635 2.356.43.43 0 0 0 .49 0 15.938 15.938 0 0 0 2.635-2.356c1.163-1.308 2.329-3.115 2.329-5.14C12.217 2.934 9.89.5 7.008.5Zm-1.86 5.358c0-1.057.833-1.913 1.86-1.913 1.028 0 1.86.856 1.86 1.913s-.832 1.914-1.86 1.914c-1.027 0-1.86-.857-1.86-1.914Z"
                                                fill="#212124"
                                            ></path>
                                        </svg>
                                        <h6>Lusaka, Zambia</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-box">
                            <div>
                                <svg
                                    width="40"
                                    height="32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M18.257 23.12c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24C10.227.9.107 5.85.107 20.59c0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Zm21.67 0c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24c-6.93.66-17.05 5.61-17.05 20.35 0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Z"
                                        fill="#EAEBEE"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <p>
                                    I love that it is
                                    <span> district-based</span>. It's easy
                                    to see the<span> seller's location </span>
                                    and cuts down on others asking what's your
                                    location. Sales are so much easier to
                                    manage.
                                </p>
                                <div className="testimonial-author">
                                    <div>
                                        <p className="para-2">Mukalula</p>
                                    </div>
                                    <div>
                                        <svg
                                            width="14"
                                            height="14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            role="img"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.008.5C4.128.5 1.8 2.934 1.8 5.926c0 2.025 1.166 3.832 2.328 5.14a15.937 15.937 0 0 0 2.635 2.356.43.43 0 0 0 .49 0 15.938 15.938 0 0 0 2.635-2.356c1.163-1.308 2.329-3.115 2.329-5.14C12.217 2.934 9.89.5 7.008.5Zm-1.86 5.358c0-1.057.833-1.913 1.86-1.913 1.028 0 1.86.856 1.86 1.913s-.832 1.914-1.86 1.914c-1.027 0-1.86-.857-1.86-1.914Z"
                                                fill="#212124"
                                            ></path>
                                        </svg>
                                        <h6>Ndola, Zambia</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-box">
                            <div>
                                <svg
                                    width="40"
                                    height="32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <path
                                        d="M18.257 23.12c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24C10.227.9.107 5.85.107 20.59c0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Zm21.67 0c0-4.62-2.86-7.59-7.81-7.59-.99 0-1.76.22-2.31.55.22-5.06 4.84-8.47 9.02-9.13V.24c-6.93.66-17.05 5.61-17.05 20.35 0 5.83 3.74 10.56 9.57 10.56 5.17 0 8.58-3.74 8.58-8.03Z"
                                        fill="#EAEBEE"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <p>
                                    If you're looking for second-hand items for
                                    <span> a cheap price</span>, this is the app
                                    for you. I've made amazing
                                    <span> deals</span>, and
                                    <span> TreeMarket users are kind</span>. If
                                    you are lucky, you can get a brand-new item
                                    at a bargain price!
                                </p>
                                <div className="testimonial-author">
                                    <div>
                                        <p className="para-2">Kambawa</p>
                                    </div>
                                    <div>
                                        <svg
                                            width="14"
                                            height="14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            role="img"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.008.5C4.128.5 1.8 2.934 1.8 5.926c0 2.025 1.166 3.832 2.328 5.14a15.937 15.937 0 0 0 2.635 2.356.43.43 0 0 0 .49 0 15.938 15.938 0 0 0 2.635-2.356c1.163-1.308 2.329-3.115 2.329-5.14C12.217 2.934 9.89.5 7.008.5Zm-1.86 5.358c0-1.057.833-1.913 1.86-1.913 1.028 0 1.86.856 1.86 1.913s-.832 1.914-1.86 1.914c-1.027 0-1.86-.857-1.86-1.914Z"
                                                fill="#212124"
                                            ></path>
                                        </svg>
                                        <h6>Kitwe, Zambia</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonial;
