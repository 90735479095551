import React from "react";
import "../assets/css/style.css";
import "../assets/css/about.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import dstGlobal from "../assets/images/dst-global.png";
// import softbank from "../assets/images/softbank.png";
// import altos from "../assets/images/altos.png";
// import goodwater from "../assets/images/goodwater.png";
// import kakao from "../assets/images/kakao.png";
// import aspex from "../assets/images/aspex.jpg";

function About() {
    return (
        <main>
            <Navbar />
            <section className="about-background"></section>
            <section className="about-section">
                <div className="container">
                    <div className="about-content">
                        <div>
                            <h2>
                                TreeMarket Making Trade Easy<br />
                                <br />
                            </h2>
                        </div>
                        <div>
                            <h3>Meet TreeMarket</h3>
                            <p>
                                We launched TreeMarket in 2020 with the aim to
                                reconnect Zambian communities and offer an easy and
                                sustainable way for people living in the same
                                district to buy and sell. Over 10 thousand
                                people around Zambia are using TreeMarket to
                                create meaningful connections with their local
                                communities.
                            </p>
                            <p>Download the app to join them today.</p>
                        </div>
                        <div>
                            {/* <h3>Investors</h3> */}
                            <div className="investors-flex">
                                {/* <div>
                                    <img src={dstGlobal} alt="DST Global" />
                                </div>
                                <div>
                                    <img src={softbank} alt="SoftBank" />
                                </div>
                                <div>
                                    <img src={altos} alt="Altos" />
                                </div>
                                <div>
                                    <img src={goodwater} alt="Goodwater" />
                                </div>
                                <div>
                                    <img src={kakao} alt="Kakao" />
                                </div>
                                <div>
                                    <img src={aspex} alt="Aspex" />
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="media-coverage">
                            <h3>Media coverage</h3>
                            <div className="link">
                                <a href="https://www.bloomberg.com/news/articles/2021-08-17/soft-bank-backed-karrot-becomes-2-7-billion-korea-unicorn">
                                    [Bloomberg] SoftBank-Backed Karrot Becomes
                                    $2.7 Billion Korea Unicorn
                                    <span> Aug 18,2021</span>
                                </a>
                            </div>
                            <div className="link">
                                <a href="https://www.bloomberg.com/news/articles/2020-12-22/tech-worker-turns-hobby-into-a-startup-nearing-unicorn-status">
                                    [Bloomberg] Tech worker turns hobby into
                                    start-up Bloomberg
                                    <span> Dec 27, 2020</span>
                                </a>
                            </div>
                            <div className="link">
                                <a href="https://techcrunch.com/2020/06/01/danggeun-market-the-south-korean-secondhand-marketplace-app-raises-33-millon-series-c/">
                                    [Techcrunch] Danggeun Market, the South
                                    Korean secondhand marketplace app, raises
                                    $33 million Series C
                                    <span> Jun 2, 2020</span>
                                </a>
                            </div>
                        </div> */}
                        <div className="contact">
                            <h3>Contact</h3>
                            <p className="para">
                                <a href="mailto:team@karrotmarket.com">
                                    Email
                                </a>
                                <span>
                                    {" "}
                                    treemarketapp@gmail.com
                                </span>
                            </p>
                            <p className="para">
                                <a href="mailto:help@karrotmarket.com">
                                    WhatsApp
                                </a>
                                <span> +26 0965965311</span>
                            </p>
                            {/* <p className="para">
                                <a href="mailto:ir@karrotmarket.com">
                                    ir@karrotmarket.com
                                </a>
                                <span>
                                    {" "}
                                    for questions related to investor relations.
                                </span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}
export default About;
