import React from "react";
import HandsImg from "../assets/images/logo.png";

function Free() {
    return (
        // Free Section
        <section className="free-section">
            <div className="container">
                <div className="free-content">
                    <div className="hand-img">
                        <img src={HandsImg} alt="Free" />
                    </div>
                    <div>
                        <h2>It's free!</h2>
                        <p>
                            We don't charge you to enjoy TreeMarket.
                            <br />
                            Trade with your neighbours for free!
                        </p>
                    </div>
                </div>
            </div>
        </section>
        // Free Section End
    );
}
export default Free;
