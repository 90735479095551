// Data For Popular Items In Homepage

const popularItems = [
    {
        name: "Test",
        img: "product_1.jpg",
        price: 100,
        city: "Lusaka",
        country: "Zambia",
    },
    {
        name: "Test",
        img: "product_2.jpg",
        price: 5000,
        city: "Kitwe",
        country: "Zambia",
    },
    {
        name: "Test",
        img: "product_3.jpg",
        price: 3500,
        city: "Ndola",
        country: "Zambia",
    },
    {
        name: "Test",
        img: "product_4.jpg",
        price: 150,
        city: "Chingola",
        country: "Zambia",
    },
    {
        name: "Test",
        img: "product_5.jpg",
        price: 270000,
        city: "Lusaka",
        country: "Zambia",
    },
    {
        name: "Test",
        img: "product_6.jpg",
        price: 250,
        city: "Lusaka",
        country: "Zambia",
    },
];

export default popularItems;
