// Data For Items In Popular Items Page

const items = [
    // Lusaka district products
    {
        name: "Test",
        img: "Product_7.JPG",
        sale: "Winter Jacket",
        price: 450,
        city: "Lusaka",
        country: "Zambia",
        favorites: 11,
        chats: 10,
    },
    {
        name: "Test",
        img: "Product_8.PNG",
        sale: "Kapenta",
        price: 0,
        city: "Lusaka",
        country: "Zambia",
        favorites: 9,
        chats: 6,
    },
    {
        name: "Test",
        img: "Product_9.JPG",
        sale: "Pants",
        price: 250,
        city: "Lusaka",
        country: "Zambia",
        favorites: 20,
        chats: 15,
    },
    {
        name: "Test",
        img: "Product_10.JPG",
        sale: "iPhone X",
        price: 4200,
        city: "Lusaka",
        country: "Zambia",
        favorites: 40,
        chats: 20,
    },
    {
        name: "Test",
        img: "Product_11.JPG",
        sale: "Cakes",
        price: 300,
        city: "Lusaka",
        country: "Zambia",
        favorites: 10,
        chats: 30,
    },
    {
        name: "Test",
        img: "Product_12.JPG",
        sale: "Jordan Shoes",
        price: 650,
        city: "Lusaka",
        country: "Zambia",
        favorites: 54,
        chats: 38,
    },

    {
        name: "Test",
        img: "product_4.jpg",
        sale: "Chickens",
        price: 150,
        city: "Lusaka",
        country: "Zambia",
        favorites: 25,
        chats: 8,
    },

    {
        name: "Test",
        img: "Product_14.JPG",
        sale: "Volkswagen Polo",
        price: 43000,
        city: "Lusaka",
        country: "Zambia",
        favorites: 12,
        chats: 28,
    },

    {
        name: "Test",
        img: "product_4.jpg",
        sale: "Moving sale!",
        price: 25,
        city: "Choma",
        country: "Zambia",
        favorites: 4,
        chats: 5,
    },
    {
        name: "Test",
        img: "product_4.jpg",
        sale: "Moving sale!",
        price: 25,
        city: "Choma",
        country: "Zambia",
        favorites: 4,
        chats: 5,
    },

    // Kafue district products
    {
        name: "Test",
        img: "Product_7.JPG",
        sale: "Winter Jacket",
        price: 450,
        city: "Kafue",
        country: "Zambia",
        favorites: 11,
        chats: 10,
    },
    {
        name: "Test",
        img: "Product_8.PNG",
        sale: "Kapenta",
        price: 0,
        city: "Kafue",
        country: "Zambia",
        favorites: 9,
        chats: 6,
    },
    {
        name: "Test",
        img: "Product_9.JPG",
        sale: "Pants",
        price: 250,
        city: "Kafue",
        country: "Zambia",
        favorites: 20,
        chats: 15,
    },
    {
        name: "Test",
        img: "Product_10.JPG",
        sale: "iPhone X",
        price: 4200,
        city: "Kafue",
        country: "Zambia",
        favorites: 40,
        chats: 20,
    },
    {
        name: "Test",
        img: "Product_11.JPG",
        sale: "Cakes",
        price: 300,
        city: "Kafue",
        country: "Zambia",
        favorites: 10,
        chats: 30,
    },
    {
        name: "Test",
        img: "Product_12.JPG",
        sale: "Jordan Shoes",
        price: 650,
        city: "Kafue",
        country: "Zambia",
        favorites: 54,
        chats: 38,
    },

    {
        name: "Test",
        img: "product_4.jpg",
        sale: "Chickens",
        price: 150,
        city: "Kafue",
        country: "Zambia",
        favorites: 25,
        chats: 8,
    },

    {
        name: "Test",
        img: "Product_14.JPG",
        sale: "Volkswagen Polo",
        price: 43000,
        city: "Kafue",
        country: "Zambia",
        favorites: 12,
        chats: 28,
    },
//  Luangwa district products
{
    name: "Test",
    img: "Product_7.JPG",
    sale: "Winter Jacket",
    price: 450,
    city: "Luangwa",
    country: "Zambia",
    favorites: 11,
    chats: 10,
},
{
    name: "Test",
    img: "Product_8.PNG",
    sale: "Kapenta",
    price: 0,
    city: "Luangwa",
    country: "Zambia",
    favorites: 9,
    chats: 6,
},
{
    name: "Test",
    img: "Product_9.JPG",
    sale: "Pants",
    price: 250,
    city: "Luangwa",
    country: "Zambia",
    favorites: 20,
    chats: 15,
},
{
    name: "Test",
    img: "Product_10.JPG",
    sale: "iPhone X",
    price: 4200,
    city: "Luangwa",
    country: "Zambia",
    favorites: 40,
    chats: 20,
},
{
    name: "Test",
    img: "Product_11.JPG",
    sale: "Cakes",
    price: 300,
    city: "Luangwa",
    country: "Zambia",
    favorites: 10,
    chats: 30,
},
{
    name: "Test",
    img: "Product_12.JPG",
    sale: "Jordan Shoes",
    price: 650,
    city: "Luangwa",
    country: "Zambia",
    favorites: 54,
    chats: 38,
},

{
    name: "Test",
    img: "product_4.jpg",
    sale: "Chickens",
    price: 150,
    city: "Luangwa",
    country: "Zambia",
    favorites: 25,
    chats: 8,
},

{
    name: "Test",
    img: "Product_14.JPG",
    sale: "Volkswagen Polo",
    price: 43000,
    city: "Luangwa",
    country: "Zambia",
    favorites: 12,
    chats: 28,
},
//  Chongwe district products
{
    name: "Test",
    img: "Product_7.JPG",
    sale: "Winter Jacket",
    price: 450,
    city: "Chongwe",
    country: "Zambia",
    favorites: 11,
    chats: 10,
},
{
    name: "Test",
    img: "Product_8.PNG",
    sale: "Kapenta",
    price: 0,
    city: "Chongwe",
    country: "Zambia",
    favorites: 9,
    chats: 6,
},
{
    name: "Test",
    img: "Product_9.JPG",
    sale: "Pants",
    price: 250,
    city: "Chongwe",
    country: "Zambia",
    favorites: 20,
    chats: 15,
},
{
    name: "Test",
    img: "Product_10.JPG",
    sale: "iPhone X",
    price: 4200,
    city: "Chongwe",
    country: "Zambia",
    favorites: 40,
    chats: 20,
},
{
    name: "Test",
    img: "Product_11.JPG",
    sale: "Cakes",
    price: 300,
    city: "Chongwe",
    country: "Zambia",
    favorites: 10,
    chats: 30,
},
{
    name: "Test",
    img: "Product_12.JPG",
    sale: "Jordan Shoes",
    price: 650,
    city: "Chongwe",
    country: "Zambia",
    favorites: 54,
    chats: 38,
},

{
    name: "Test",
    img: "product_4.jpg",
    sale: "Chickens",
    price: 150,
    city: "Chongwe",
    country: "Zambia",
    favorites: 25,
    chats: 8,
},

{
    name: "Test",
    img: "Product_14.JPG",
    sale: "Volkswagen Polo",
    price: 43000,
    city: "Chongwe",
    country: "Zambia",
    favorites: 12,
    chats: 28,
},

];

export default items;
