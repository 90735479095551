import React from "react";
function More(props) {
    return (
        <section className="more-section">
            <div className="container">
                <div className="more-content">
                    <h5>Want to see more in {props.city}?</h5>
                    <p>Join our private community for verified locals today!</p>

                    <a
                        href="https://apps.apple.com/us/app/treemarket/id1581571176"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn"
                    >
                        <svg
                            width="18"
                            height="20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.642 4.466c2.317 0 3.8 2.242 3.8 2.242S14.18 7.935 14.18 10.6c0 3.162 2.84 4.082 2.84 4.082S14.779 20 12.262 20c-1.415 0-1.51-.814-3.39-.814-1.634 0-2.185.814-3.43.814C3.069 20 0 14.816 0 10.6c0-4.38 3.106-6.134 5.053-6.134 1.71 0 2.422 1.006 3.8 1.006 1.158 0 2.07-1.006 3.789-1.006ZM12.243 0c.342 2.147-1.605 4.801-3.932 4.715C7.97 1.984 10.477.145 12.243 0Z"
                                fill="#fff"
                            ></path>
                        </svg>{" "}
                        App Store
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=brecha.treemarket.intapp&gl=US"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn"
                    >
                        <svg
                            width="18"
                            height="21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M.588 20.038C.232 19.942 0 19.588 0 19.04V.973C0 .466.196.124.508 0l8.877 10.069-8.797 9.969Zm1.222-.384 10.685-6.057-2.602-2.952-8.083 9.009ZM12.57 6.46 2.033.486l7.875 8.99 2.66-3.016Zm.715.405 4.127 2.34c.782.444.789 1.158 0 1.605l-4.232 2.4-2.76-3.152 2.865-3.193Z"
                                fill="#fff"
                            ></path>
                        </svg>{" "}
                        Google Play
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=brecha.treemarket.intapp&gl=US"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn mobile"
                    >
                        Download TreeMarket
                    </a>
                </div>
            </div>
        </section>
    );
}
export default More;
