// Cities And Countries Data For Popular Items Page Filters

const locations = [
    {
        country: "Zambia",
        cities: [
            "Lusaka",
            "Kafue",
            "Luangwa",
            "Chongwe",
            "Kitwe",
            "Ndola",
            "Chililabombwe",
            "Chingola",
            "Kabwe",
            "Kapiri-Mposhi",
            "Mkushi",
            "Mubwa",
            "Chadiza",
            "Chipata",
            "Chasafu",
            "Chipangali",
            "Chembe",
            "Chiegi",
            "Chipili",
            "Kawambwa",
            "Chilubi",
            "Kaputa",
            "Kasama",
            "Luwingu",
            "Chama",
            "Chinsali",
            "Chilinda",
            "Isoka",
            "Chavuma",
            "Ikelenge",
            "Kabompo",
            "Kalumbila",
            "Choma",
            "Chikankata",
            "Gwembe",
            "Kalomo",
            "Kalabo",
            "Kaoma",
            "Limulungu",
            "Luampa",
        ],
    },
];

export default locations;
