import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import heroImg from "../assets/images/hero_img.jpg";
import item_1 from "../assets/images/items/item_1.jpg";
import item_2 from "../assets/images/items/item_2.jpg";
import item_3 from "../assets/images/items/item_3.jpg";
import item_4 from "../assets/images/items/item_4.jpg";

function Hero() {
    // On Scroll Animation Initialize
    useEffect(() => {
        AOS.init({
            duration: 500,
            delay: 100,
            once: true,
        });
    }, []);

    return (
        // Hero Section
        <section className="hero-section">
            <div className="container">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1>
                            Discover New <br /> and Used items <br /> within
                            your district
                        </h1>
                        <a
                            href="https://apps.apple.com/kr/app/treemarket/id1581571176?l=e"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="btn"
                        >
                            <svg
                                width="18"
                                height="20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.642 4.466c2.317 0 3.8 2.242 3.8 2.242S14.18 7.935 14.18 10.6c0 3.162 2.84 4.082 2.84 4.082S14.779 20 12.262 20c-1.415 0-1.51-.814-3.39-.814-1.634 0-2.185.814-3.43.814C3.069 20 0 14.816 0 10.6c0-4.38 3.106-6.134 5.053-6.134 1.71 0 2.422 1.006 3.8 1.006 1.158 0 2.07-1.006 3.789-1.006ZM12.243 0c.342 2.147-1.605 4.801-3.932 4.715C7.97 1.984 10.477.145 12.243 0Z"
                                    fill="#fff"
                                ></path>
                            </svg>{" "}
                            App Store
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=brecha.treemarket.intapp&gl=US"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="btn"
                        >
                            <svg
                                width="18"
                                height="21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.588 20.038C.232 19.942 0 19.588 0 19.04V.973C0 .466.196.124.508 0l8.877 10.069-8.797 9.969Zm1.222-.384 10.685-6.057-2.602-2.952-8.083 9.009ZM12.57 6.46 2.033.486l7.875 8.99 2.66-3.016Zm.715.405 4.127 2.34c.782.444.789 1.158 0 1.605l-4.232 2.4-2.76-3.152 2.865-3.193Z"
                                    fill="#fff"
                                ></path>
                            </svg>{" "}
                            Google Play
                        </a>
                    </div>
                    <div className="mockup-img">
                        <img src={heroImg} alt="Tree Market" />
                        <div className="mockup-items">
                            <img
                                src={item_1}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="500"
                            />
                            <img
                                src={item_2}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="1000"
                            />
                            <img
                                src={item_3}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="1500"
                            />
                            <img
                                src={item_4}
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="2000"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // Hero Section End
    );
}
export default Hero;
