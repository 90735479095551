import React from "react";
import logo from "../assets/images/logo.png"
function Marketplace() {
    return (
        <section className="market-section">
            <div className="container">
                <div className="market-content">
                    <div>
                        <h4>
                            {/* <img src={logo} alt="Tree Market" />{" "} */}
                            What is TreeMarket?
                        </h4>
                        <h2>
                            It is an e-commerce platform that
                            <br />
                            makes trade easy for all Zambians
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Marketplace;
